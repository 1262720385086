


export const getSubdomain = () =>{
    const fullDomain = window.location.hostname;
    const domainParts = fullDomain.split('.');
    let subdomain =''
    // Check if the domain contains a subdomain
    if (domainParts.length > 2) {
      // Extract the subdomain (assuming a standard 2-part TLD, like .com, .net, etc.)
      subdomain = domainParts.slice(0, -2).join('.');
      
    }
    return subdomain;
}