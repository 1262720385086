/* eslint-disable no-undef */

const getInfoDesignPm = (uuid) => `/property-managers/${uuid}/info/`;

const getInfoDesignSubmain = (subdomain) => `/property-managers/info/?subdomain=${subdomain}`;

const sendOTPCode = `/landlords/subscriptions/otp/send/`;

const urls = {
  getInfoDesignPm,
  sendOTPCode,
  getInfoDesignSubmain
};

export { urls };
