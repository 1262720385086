import React from 'react';

const FirstSection = () => {
  return (
    <section className="landing-first-section">
      <div className="white-container">
        <h1 className="title">What is the Owner Benefit Package™?</h1>
        <p>
          The Owner Benefit Package™ is an innovative property management
          offering that provides a comprehensive range of protections and
          services delivering additional value to property investors.
        </p>
        <h2 className="subtitle">What’s included in OBP™?</h2>
        <ol>
          <li>Up to 2 months of rent protection</li>
          <li>Up to $1,000 in legal and re-tenanting expenses*</li>
          <li>
            Access to <strong>Rent Advance™</strong>
          </li>
          <li>Market and asset level reporting*</li>
          <li>Real-time legislative alerts on rental housing laws</li>
        </ol>
        <p className="ghost-text">
          *Benefit only offered on specific package types outlined in OBP™
          subscription management details
        </p>
      </div>
    </section>
  );
};

export default FirstSection;
