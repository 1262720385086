import React from 'react';
import PresentationCard from './PresentationCard';

const WhySection = () => {
  const protections = [
    {
      id: '1',
      title: 'Protections',
      description: ['Following a tenant’s default on rent:'],
      descriptionList: [
        'Up to two months of protected rental income',
        'Up to $1,000 towards the listing of a unit, legal costs of an eviction, or other legal action'
      ],
      image: '/icons/protection.svg'
    },
    {
      id: '2',
      title: 'Access to Capital',
      description: [
        'Rent Advance™ provides property investors with up to 12 months of rent payments upfront on qualifying leases'
      ],
      descriptionList: [],
      image: '/icons/capital.svg'
    },
    {
      id: '3',
      title: 'Investor Awareness',
      description: [
        'As new federal and state legislation is proposed, stay updated on how the proposed bill may impact your rental investment',
        'Access to accurate rental listing comparables provided in an OBP™ rental report '
      ],
      descriptionList: [],
      image: '/icons/investor.png'
    }
  ];
  return (
    <section className="cards-section">
      <h2>Why investors love the Owner Benefit Package™</h2>

      <div className="package-cards-container">
        {protections.map((item) => (
          <div key={item.name}>
            <PresentationCard
              key={item.name}
              title={item.title}
              description={item.description}
              descriptionList={item.descriptionList}
              features={item.features}
              color={item.color}
              image={item.image}
              featured={item.featured}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhySection;
