import React, { useEffect, useState } from 'react';
import ObpLandingLayout from 'components/ObpLandingLayout';
import Form from 'components/form';
import { Button, Drawer } from 'antd';
import { getDesignPmSubmain } from 'services/apiService';
import CircularProgress from 'components/CircularProgress';
import { useNavigate } from 'react-router-dom';
import FirstSection from 'components/dynamicLanding/FirstSection';
import CalculatorSection from 'components/dynamicLanding/CalculatorSection';
import { getSubdomain } from 'utils/domain';
import CardsSection from 'components/dynamicLanding/CardsSection';
// import TestimonialsSection from 'components/landing/TestimonialsSection';
// import FAQ from 'components/FAQ';

const ObpDynamic = () => {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [design, setDesign] = useState({});
  const navigate = useNavigate();
  const includesSubscribe = window.location.href.includes('subscribe');

  const handleButtonClick = () => {
    document
      .getElementById('second-component')
      .scrollIntoView({ behavior: 'smooth' });
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const urlDomain =
    process.env.REACT_APP_DOMAIN != undefined
      ? process.env.REACT_APP_DOMAIN
      : '';

  const isIntoBlackList = (subdomain) => {
    return (
      subdomain === 'qa.getobp.com.s3-website-us-east-1' ||
      subdomain === 'stg.getobp.com.s3-website-us-east-1' ||
      subdomain === 'www'
    );
  };

  console.log('getSubdomain', getSubdomain());

  useEffect(() => {
    let subdomain = getSubdomain();

    if (subdomain != '' && !isIntoBlackList(subdomain)) {
      getInfoLandingSubdomain(subdomain);
    } else {
      navigate('/not-found');
    }
  }, []);

  const getInfoLandingSubdomain = async (subdomain) => {
    try {
      setLoader(true);
      let infoDesign = await getDesignPmSubmain(subdomain);
      let data = { ...infoDesign.data };
      console.log('data', data);
      setDesign(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      window.location.href = `${urlDomain}/not-found`;
      console.log('error', error);
    }
  };
  console.log('ref ref', window.location.href.includes('subscribe'));

  // window.innerWidth > 1200 ? 700 : 700
  return (
    <>
      {loader && (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
      <ObpLandingLayout
        button={
          includesSubscribe ? (
            ''
          ) : (
            <Button
              onClick={showDrawer}
              className="btn-green btn-mobile"
              style={{ margin: 0 }}
            >
              Manage OBP™ Subscription
            </Button>
          )
        }
        obpFee={design?.default_monthly_obp_fee}
        logo={design?.logo_img}
        sizeLogo={'large'}
        loader={loader}
        content={
          <>
            <Drawer
              title=""
              onClose={onClose}
              closable={false}
              open={open}
              width={380}
            >
              <Form
                title={'Change your subscriptions settings'}
                pm_id={design?.id}
                onLoader={(status) => {
                  setLoader(status);
                }}
              />
            </Drawer>
            <FirstSection onButtonClick={handleButtonClick} />
            <CardsSection />
            <CalculatorSection />
            {/* <TestimonialsSection /> */}
            {/* <FAQ /> */}
          </>
        }
      />
    </>
  );
};

export default ObpDynamic;
