import React from 'react';
// import { Divider } from 'antd';

const PresentationCard = (props) => {
  const { title, description, image, descriptionList } = props;
  const features = props.features;

  return (
    <div className="package-card">
      <div className="color-corner"></div>
      <img
        src="/icons/star.svg"
        width={25}
        className="star"
        style={{ display: title == 'Plus' ? 'block' : 'none' }}
      />
      <div className="image-container">
        <img src={image} width={80} />
      </div>

      <h3 className="package-title">{title}</h3>
      <div className="presentation-description-container">
        {description.map((text) => (
          <p key={text} className="package-description">
            {text}
          </p>
        ))}
        {descriptionList && (
          <ul className='description-list'>
            {descriptionList.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        )}
      </div>

      {/* <Divider /> */}
      <p className="included-text">
        {props.features && <strong>What’s included:</strong>}
      </p>

      <div className="features-container">
        {features?.map((item) => (
          <div className="feature-item" key={item}>
            <span>
              <img src="/icons/check.svg" />{' '}
            </span>
            <p style={{ marginLeft: '5px' }}>{item}</p>
          </div>
        ))}
      </div>
      {/* <Divider />
      <Button
        type="button"
        className="save-button"
        // loading={loading}
        id="save-button"
        data-testid="save-button"
        style={{ margin: 0 }}
        onClick={() => action}
      >
        Request Pricing
      </Button> */}
    </div>
  );
};

export default PresentationCard;
