export const isNotEmpty = (value) => {
  const regex = /\S+/; // Match one or more non-whitespace characters
  return regex.test(value);
};

export const isExactMatch = (inputValue, existingValue) => {
  return inputValue == existingValue;
};

export const verifyPhone = (phone) => {
  const reg = {
    // eslint-disable-next-line no-useless-escape
    phone: /^((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/
  };

  // ^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$
  // ^[1]{1}(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$

  if (phone.length < 8 || !reg.phone.test(phone)) {
    return false;
  }
  return true;
};

export const verifyPassword = (password) => {
  const reg = {
    capital: /[A-Z]/,
    number: /[0-9]/,
    full: /^[A-Za-z0-9@#]{8,13}$/,
    length: /^[a-zA-Z][0-9]{8}$/
  };

  if (/\s/.test(password)) {
    return false;
  }

  if (
    password.length < 8 ||
    !reg.capital.test(password) ||
    !reg.number.test(password)
  ) {
    return false;
  }

  return true;
};

export const verifyEmail = (email) => {
  const reg = {
    // eslint-disable-next-line no-useless-escape
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  };

  if (email.length < 8 || !reg.email.test(email)) {
    return false;
  }
  return true;
};

export const verifySixDigitCode = (code) => {
  if (code === undefined || code === null || typeof code !== 'string') {
    return false;
  }

  const reg = {
    code: /^\d{6}$/
  };

  if (code.length !== 6 || !reg.code.test(code)) {
    return false;
  }

  return true;
};

export const verifyStringLength = (str) => {
  const reg = {
    myString: /^[a-zA-Z\s]{1,}$/
  };

  if (str.length < 1 || !reg.myString.test(str)) {
    return false;
  }
  return true;
};

export const verifyZipCode = (zipCode) => {
  const reg = {
    zip: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/
  };

  if (!reg.zip.test(zipCode)) {
    return false;
  }
  return true;
};

export const getError = (obj) => {
  let errorMessages = '';
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      let messageArray = '';
      obj[key].forEach((element) => {
        messageArray += (messageArray != '' ? ',\n' : '') + element;
      });
      errorMessages += messageArray;
    } else if (typeof obj[key] === 'object') {
      // If the property is an object, recursively call extractErrorMessages
      errorMessages += ',\n' + getError(obj[key]);
    }
  }
  return errorMessages;
};

export const verifyNumber = (value) => {
  const reg = /^\d{10}$/;

  if (!reg.test(value)) {
    return false;
  }
  return true;
};

export const verifyNumberOTP = (value) => {
  const reg = /^\d{6}$/;

  if (!reg.test(value)) {
    return false;
  }
  return true;
};

//^[0-9]+$

export const verifyMgmt = (value) => {
  const reg = /^[+]?(?:\d*\.)?\d+$/;
  if (!reg.test(value)) {
    return false;
  }
  if (parseFloat(value) <= 1.0) {
    return true;
  }
  return false;
};

export const verifyEinNumber = (number) => {
  const reg =
    /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/;

  if (!reg.test(number)) {
    return false;
  }
  return true;
};

export const cleanPhone = (phoneNumber) => {
  let phone = phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`;
  phone = phone
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace('-', '');
  return phone;
};

export const verifyDayNumber = (number) => {
  const reg = /^(0?[1-9]|[12]\d|3[01])$/; // Updated regular expression

  if (!reg.test(number)) {
    return false;
  }
  return true;
};

export const dateGreaterThanDate = (date1, date2) => {
  const [month1, day1, year1] = date1.split('-').map(Number);
  const [month2, day2, year2] = date2.split('-').map(Number);

  if (year1 > year2) {
    return true;
  } else if (year1 === year2 && month1 > month2) {
    return true;
  } else if (year1 === year2 && month1 === month2 && day1 > day2) {
    return true;
  }

  return false;
};
