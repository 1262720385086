import React from 'react';

const SteadyFooter = () => {
  //const year = new Date().getFullYear().toString();

  // Steady Technologies, Inc. and its subsidiaries deliver unique
  //           financial and insurance solutions to property managers and their
  //           clients. All insurance products are offered through Steady Insurance
  //           Agency LLC, a leading program manager and Underwriting Manager for
  //           Landlord Rent Default Insurance. Steady Insurance Agency LLC works
  //           with an A.M. Best rated A+ and Fortune 100 Company. Nationwide, the
  //           Nationwide N and Eagle are service marks of Nationwide Mutual
  //           Insurance Company. © 2022 Nationwide Mutual Insurance Company.
  //           Steady Insurance Agency LLC or Steady Insurance Agency LLC`s brokers
  //           maintain Excess and Surplus Lines Broker licenses in every state
  //           where Landlord Rent Default Policies are bound and issued.

  return (
    <footer className="steady-footer">
      <div className="first-content">
        <div className="copyright-container">
          {/* <img src="icons/logo-white.png" alt="" /> */}
          <p>
          Owner Benefit Package LLC 2024
          </p>
        </div>

        {/* QUICK LINKS */}
        {/* <div className="quick-links">
          <h3>QUICK LINKS</h3>
          <ul>
            <li>
              <span></span>FAQ
            </li>
            <li>
              <span></span>ABOUT US
            </li>
            <li>
              <span></span>CONTACT US
            </li>
          </ul>
        </div> */}
      </div>

      {/* divider */}
      {/* <div className="footer-divider"></div> */}

      {/* copyright */}
      <div className="second-content footer-copyright">
        {/* copyrigth */}
        {/* <div className="copyright-container">
          <p>Steady Technologies, Inc. {year}. All rights reserved.</p>
        </div> */}

        {/* privacy & policy */}
        {/* <div className="privacy-container">
          <a>Sitemap</a>
          <span></span>
          <a>Terms of use</a>
          <span></span>
          <a>Privacy policy</a>
        </div> */}

        {/* SOCIAL */}
        {/* <div className="social-container">
          <a>
            <img src="icons/fb.png" />
          </a>
          <a>
            <img src="icons/in.png" />
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default SteadyFooter;
