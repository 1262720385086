import React, { useState } from 'react';
//import Footer from './Footer';
import SteadyFooter from './SteadyFooter';

const ObpLandingLayout = (props) => {
  const { content, button, logo, loader, sizeLogo } = props; //, obpFee
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <div className="obp-landing-container">
      {/* LOGO HEADER */}
      <header className="obp-landing-navbar">
        <div className="mobile-hidden flex-center "></div>
        <div className="flex-center">
          {imageError ? (
            <img
              className="logo-osm-header"
              src="../../icons/obp_logo.svg"
              alt="obp-logo"
            />
          ) : (loader?<div></div>:
            <img
              src={logo != undefined ? logo : '../../icons/obp_logo.svg'}
              alt="logo"
              onError={handleImageError}
              className={sizeLogo=='large'?"logo-osm-header-suscribe":"logo-osm-header"}
            />
          )}
        </div>
        <div className="flex-right">{button}</div>
      </header>

      {/* CONTENT */}
      <div className="osm-landing-content-container">{content}</div>
      {/* FOOTER */}
      <SteadyFooter />
      {/* <footer className="osm-footer">
        <p className="disclaimer">
          Disclaimer: By not electing to opt-out of the Owner Benefit Package™
          your rental units will be automatically subscribed in the services
          offered above. An additional ${obpFee} per month will be added to your
          management fee. Your decision to not sign this opt-out hereby
          authorizes Property Manager and its service providers or their
          affiliates, if applicable, to act as a limited attorney-in-fact for
          the sole purpose to purchase or determine qualification for rent
          default insurance on your behalf for your rental units and to receive
          any documentation from the insurer for this coverage. Additionally,
          Property Manager, its service providers or their affiliates, if
          applicable, will be authorized to provide such insurer or its agent
          any information about you, the leases, and your tenants, as required
          by the insurer to establish eligibility for insurance coverage.
          Opt-out forms submitted within the first thirty (30) days of
          subscription will be credited back to you. If this form is not
          completed and submitted within thirty (30) days, credits for past
          charges will not be applied.;
        </p>
      </footer> */}
    </div>
  );
};

export default ObpLandingLayout;
