import React from 'react';
// import Lottie from "lottie-react";
import { Layout } from 'antd';
import { Player } from '@lottiefiles/react-lottie-player';

const Page404 = () => {
  const { Content } = Layout;

  return (
    <Layout className="main-layout">
      <Content className="main-content">
        <Player
          id="lottie-loader"
          autoplay
          loop
          src="/animations/load404.json"
          style={{ height: '500px', width: '500px', marginTop: '100px' }}
        />
      </Content>
    </Layout>
  );
};

export default Page404;
