import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import load from './loader-point.json';

function CircularProgress() {
  return (
    <Player
      id="lottie-loader"
      data-testid="lottie-loader"
      autoplay
      loop
      src={load}
      style={{ height: '300px', width: '300px', marginTop: '100px' }}
    />
  );
}

export default CircularProgress;
CircularProgress.defaultProps = {
  className: ''
};
