/* eslint-disable no-undef */
import axios from 'axios';
import { urls } from './urls';

const urlBase =
  process.env.REACT_APP_API_URL != undefined
    ? process.env.REACT_APP_API_URL
    : '';

const instance = axios.create({
  baseURL: urlBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export const getDesignPm = (id) => instance.get(urls.getInfoDesignPm(id));

export const getDesignPmSubmain = (subdomain) => instance.get(urls.getInfoDesignSubmain(subdomain));
export const sendVerificationCode = (payload) =>
  instance.post(urls.sendOTPCode, payload);
