import React, { useState } from 'react';
import { Select } from 'antd';

const ObpCost = () => {
  const [calculator, setCalculator] = useState({
    cost_of_obp_plus: '',
    years_of_obp: '3',
    monthly_rent: '',
    show_results: false,
    obp_cost_x_years: '',
    covered_loss: '',
    total_covered_loss: '',
    savings: ''
  });
  const [seeDetails, setSeeDetails] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (fieldName, value) => {
    // Remove non-numeric characters except decimal point
    let cleanValue = value.replace(/[^0-9.]/g, '');

    // Update the state with the cleaned value
    setCalculator((prev) => ({
      ...prev,
      [fieldName]: cleanValue
    }));
  };

  // Function to get the formatted value for display purposes
  const getFormattedValue = (rawValue) => {
    if (rawValue === '') return '';
    let num = parseFloat(rawValue);
    if (isNaN(num)) return '';
    return `$${num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })}`;
  };

  function calculate() {
    console.log('calculate', calculator);
    const monthlyRent = parseFloat(
      calculator.monthly_rent.replace(/[^0-9.]/g, '').replace(/$/g, '')
    );
    const yearsOfProtection = parseFloat(
      calculator.years_of_obp.replace(/[^0-9.]/g, '')
    );
    const cost_of_obp_plus = parseFloat(
      calculator.cost_of_obp_plus.replace(/[^0-9.]/g, '')
    );

    if (
      (calculator.monthly_rent == '' && calculator.cost_of_obp_plus == '') ||
      isNaN(monthlyRent) ||
      isNaN(yearsOfProtection) ||
      isNaN(cost_of_obp_plus)
    ) {
      setError('Complete all three fields ');
      return;
    } else {
      setError('');
    }

    const obpCostXYears = cost_of_obp_plus * 12 * yearsOfProtection;
    const coveredLoss = monthlyRent * 2;
    const totalCoveredLoss = coveredLoss + 1000;
    const savings = totalCoveredLoss - obpCostXYears;

    setCalculator((prev) => ({
      ...prev,

      obp_cost_x_years: `$${obpCostXYears.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}`,

      covered_loss: `$${coveredLoss.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}`,
      total_covered_loss: `$${totalCoveredLoss.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}`,
      savings: `$${savings.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })}`,
      show_results: true
    }));
  }

  const handleSeeDetails = () => {
    setSeeDetails(!seeDetails);
  };

  const getDetailsMobile = () => {
    return (
      <>
        <div>
          {/* benefit */}
          <div>
            <h2 className="blue-title">Benefit</h2>

            <div>
              <p className="result-value">{getFormattedValue(calculator.monthly_rent)}</p>
              <p className="label-value">Monthly Rent</p>
            </div>

            <div className="calculator-equation-row">
              <img src="/icons/Cancel.svg" />
              <div>
                <p className="constant-value">2 </p>
                <p className="label-value">months of rent protection</p>
              </div>
            </div>

            <div className="calculator-equation-row">
              <img src="/icons/Equal.svg" />
              <div>
                <p className="result-value">{calculator.covered_loss}</p>
                <p className="label-value">
                  Covered loss on 1 eviction with OBP™
                </p>
              </div>
            </div>

            <div className="calculator-equation-row">
              <img src="/icons/Plus.svg" />
              <div>
                <p className="constant-value">$1,000 </p>
                <p className="label-value">Covered legal expenses</p>
              </div>
            </div>

            <hr />
            <div>
              <p className="total-value">{calculator.total_covered_loss}</p>
              <p className="label-value">
                Total covered loss on 1 eviction with OBP™
              </p>
            </div>
          </div>
          <br />
          {/* cost */}
          <div>
            <h2 className="blue-title">Cost</h2>

            <div>
              <p className="result-value">${calculator.cost_of_obp_plus}</p>
              <p className="label-value">Monthly cost of OBP™ Plus</p>
            </div>

            <div className="calculator-equation-row">
              <img src="/icons/Cancel.svg" />
              <div>
                <p className="constant-value">12 </p>
                <p className="label-value">months</p>
              </div>
            </div>

            <div className="calculator-equation-row">
              <img src="/icons/Cancel.svg" />
              <div>
                <p className="result-value">{calculator.years_of_obp}</p>
                <p className="label-value">Years of OBP™ protection</p>
              </div>
            </div>

            <hr />

            <div>
              <p className="total-value">{calculator.obp_cost_x_years}</p>
              <p className="label-value">
                Cost of OBP™ coverage for {calculator.years_of_obp} years
              </p>
            </div>
          </div>

          <hr />
        </div>

        <input
          type="button"
          onClick={() =>
            setCalculator((prev) => ({
              ...prev,
              show_results: false
            }))
          }
          className="button-calculator"
          value={'Go back'}
        />
      </>
    );
  };

  const getDetails = () => {
    return (
      <>
        {/* benefit */}
        <div className="calculator-container show-web results-container">
          <h2 className="blue-title">Benefit</h2>

          <div>
            <p className="result-value">{getFormattedValue(calculator.monthly_rent)}</p>
            <p className="label-value">Monthly Rent</p>
          </div>

          <div className="calculator-equation-row">
            <img src="/icons/Cancel.svg" />
            <div>
              <p className="constant-value">2 </p>
              <p className="label-value">months of rent protection</p>
            </div>
          </div>

          <div className="calculator-equation-row">
            <img src="/icons/Equal.svg" />
            <div>
              <p className="result-value">{calculator.covered_loss}</p>
              <p className="label-value">Covered loss on 1 eviction with OBP™</p>
            </div>
          </div>

          <div className="calculator-equation-row">
            <img src="/icons/Plus.svg" />
            <div>
              <p className="constant-value">$1,000 </p>
              <p className="label-value">Covered legal expenses</p>
            </div>
          </div>

          <hr />
          <div>
            <p className="total-value">{calculator.total_covered_loss}</p>
            <p className="label-value">
              Total covered loss on 1 eviction with OBP™
            </p>
          </div>
          {/* <img src="/icons/Equal.svg" /> */}
        </div>

        {/* cost */}
        <div className="calculator-container show-web results-container">
          <h2 className="blue-title">Cost</h2>

          <div>
            <p className="result-value">${calculator.cost_of_obp_plus}</p>
            <p className="label-value">Monthly cost of OBP™ Plus</p>
          </div>

          <div className="calculator-equation-row">
            <img src="/icons/Cancel.svg" />
            <div>
              <p className="constant-value">12 </p>
              <p className="label-value">months</p>
            </div>
          </div>

          <div className="calculator-equation-row">
            <img src="/icons/Cancel.svg" />
            <div>
              <p className="result-value">{calculator.years_of_obp}</p>
              <p className="label-value">Years of OBP™ protection</p>
            </div>
          </div>

          <hr />

          <div>
            <p className="total-value">{calculator.obp_cost_x_years}</p>
            <p className="label-value">
              Cost of OBP™ coverage for {calculator.years_of_obp} years
            </p>
          </div>

          {/* <img src="/icons/Equal.svg" /> */}
        </div>
      </>
    );
  };

  return (
    <div className="calculator-row">
      <div className="calculator-container">
        <div
          className="calculator"
          style={{ display: calculator.show_results ? 'none' : 'block' }}
        >
          <p>
            Please add your monthly OBP™ subscription fee paid for protections on
            your unit:
          </p>
          {/* editable */}
          <div className="field-content">
            <label htmlFor="cost_of_obp_plus">Monthly cost of OBP™ Plus</label>
            <input
              type="text"
              id="cost_of_obp_plus"
              name="cost_of_obp_plus"
              placeholder="$75"
              value={getFormattedValue(calculator.cost_of_obp_plus)}
              onChange={(e) => {
                handleChange('cost_of_obp_plus', e.target.value);
                setError('');
              }}
            />
          </div>

          <div className="field-content">
            <label htmlFor="years_of_obp">Years of OBP™ protection</label>
            <Select
              className="select-years-of-obp"
              defaultValue="3"
              style={{ width: 'clamp(120px, 100%, 317px)' }}
              onChange={(e) => handleChange('years_of_obp', e)}
              options={[
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' }
              ]}
            />
          </div>

          <p>Please add the monthly rent charged on your unit:</p>

          <div className="field-content">
            <label htmlFor="total-loss">Monthly Rent</label>
            <input
              type="text"
              id="monthly-rent"
              name="monthly-rent"
              placeholder="$1,000"
              value={getFormattedValue(calculator.monthly_rent)}
              onChange={(e) => {
                handleChange('monthly_rent', e.target.value);
                setError('');
              }}
            />
          </div>
          {error && (
            <div id="error-msg" data-testid="error-msg" className="error-msg">
              {error}
            </div>
          )}

          <button
            type="button"
            onClick={() => calculate()}
            value={'Calculate'}
            className="button-calculator"
          >
            Calculate
          </button>
        </div>
        {/* savings */}
        <div
          className="result"
          style={{ display: calculator.show_results ? 'block' : 'none' }}
        >
          <h2 className="blue-title">Savings with OBP™ Plus</h2>

          <p className="savings-value">{calculator.savings}</p>
          <p className="savings-label-value">
            Total Savings from {calculator.years_of_obp} years of protection
          </p>

          <div className="flex-between">
            <p className="text">Benefit</p>
            <p className="result-value">{calculator.total_covered_loss}</p>
          </div>
          <div className="flex-between">
            <p className="savings-label-value">Cost</p>
            <p className="result-value">{calculator.obp_cost_x_years}</p>
          </div>
          <input
            type="text"
            value="See Calculation Details"
            onClick={handleSeeDetails}
            className="see-details show-mobile"
          />

          <hr />

          <div
            className="show-mobile"
            style={{ display: seeDetails ? 'block' : 'none' }}
          >
            {getDetailsMobile()}
          </div>

          <input
            type="button"
            onClick={() =>
              setCalculator((prev) => ({
                ...prev,
                show_results: false
              }))
            }
            style={{ marginTop: '80px' }}
            className="button-calculator show-web"
            value={'Go back'}
          />
        </div>
      </div>

      {calculator.show_results && getDetails()}
    </div>
  );
};

export default ObpCost;
