/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import ObpLandingLayout from 'components/ObpLandingLayout';
import Form from 'components/form';
import { Button, Drawer } from 'antd';
import { getDesignPm, getDesignPmSubmain } from 'services/apiService';
import CircularProgress from 'components/CircularProgress';
import FirstSection from 'components/landing/FirstSection';
import SecondSection from 'components/landing/SecondSection';

import { getSubdomain } from 'utils/domain';

const ObpLanding = () => {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [design, setDesign] = useState({});

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const urlDomain =
    process.env.REACT_APP_DOMAIN != undefined
      ? process.env.REACT_APP_DOMAIN
      : '';

  const isIntoBlackList = (subdomain) => {
    return (
      subdomain === 'qa.getobp.com.s3-website-us-east-1' ||
      subdomain === 'stg.getobp.com.s3-website-us-east-1' ||
      subdomain === 'www'
    );
  };

  console.log('getSubdomain', getSubdomain());
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const entries = params.entries();
    const next = entries.next();
    let subdomain = getSubdomain();

    if (subdomain != '' && !isIntoBlackList(subdomain)) {
      getInfoLandingSubdomain(subdomain);
    } else if (next?.value != undefined && next?.value.length > 0) {
      const pm_id = next.value[0];
      console.log(pm_id);
      if (pm_id) {
        getInfoLanding(pm_id);
      }
    }
  }, []);

  const getInfoLanding = async (pm_info_uuid) => {
    try {
      setLoader(true);
      let infoDesign = await getDesignPm(pm_info_uuid);
      let data = { id: pm_info_uuid, ...infoDesign.data };
      console.log('data', data);
      setDesign(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      window.location.href = urlDomain;
      console.log('error', error);
    }
  };

  const getInfoLandingSubdomain = async (subdomain) => {
    try {
      setLoader(true);
      let infoDesign = await getDesignPmSubmain(subdomain);
      let data = { ...infoDesign.data };
      console.log('data', data);
      setDesign(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      window.location.href = urlDomain;
      console.log('error', error);
    }
  };

  const title =
    'Please enter your information below to find your rental units.';
  // window.innerWidth > 1200 ? 700 : 700
  return (
    <>
      {loader && (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
      <ObpLandingLayout
        button={
          <Button
            onClick={showDrawer}
            className="btn-green btn-mobile"
            style={{ margin: 0 }}
          >
            Manage OBP™ Subscription
          </Button>
        }
        obpFee={design?.default_monthly_obp_fee}
        logo={design?.logo_img}
        loader={loader}
        content={
          <>
            <Drawer
              title=""
              onClose={onClose}
              closable={false}
              open={open}
              width={380}
            >
              <Form
                title={title}
                pm_id={design?.id}
                onLoader={(status) => {
                  setLoader(status);
                }}
              />
            </Drawer>
            <FirstSection />
            <SecondSection />
          </>
        }
      />
    </>
  );
};

export default ObpLanding;
