import React from 'react';
import ObpLanding from 'page/obp-landing';
import ObpDynamic from 'page/obp-dynamic';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Page404 from 'page/Page404';

const router = createBrowserRouter([
  {
    path: '*',
    element: <ObpLanding />
  },
  { path: '/subscribe', element: <ObpDynamic /> },
  { path: '/not-found', element: <Page404 /> }
]);

const AppRouter = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default AppRouter;
